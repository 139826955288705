import React from 'react';

import './header.scss'

function Header() {
  return (
    <div id="header">
      <div className="container">
        <div className="row">
          <div className="col-md-6 rounded py-2" style={{background: "rgba(255, 255, 255, 0.5)"}}>
            <h1 className="text-left my-0 font-weight-bolder text-red">Ruby Jacob</h1>
            <h3 className="text-left text-red my-0 text-smaller font-weight-lighter">Real Estate Agent with an Engineering Degree</h3>
          </div>
          <div className="offset-md-4 col-md-2">
            <img src="solid_source_white.png" alt="solid source icon" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Header