import React from 'react';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import 'bootstrap/dist/js/bootstrap.min.js'

import Header from './componenets/header/header'
import Home from './componenets/home/home'


library.add(fab, faArrowDown, faEnvelope, faPhone)

function App() {
  return (
    <div className="app">
      <Header />
      <Home />
    </div>
  );
}

export default App;
