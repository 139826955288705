import React from 'react';

import './home.scss';
import Menu from '../menu/menu';
import Footer from '../footer/footer';

function Home() {
  return (
    <div id="home">
      <Menu />
      <div id="content" className="container">
        <div className="row pt-5">
          <div className="col-md-5">
            <h4 className="text-uppercase font-weight-bolder text-left">it is all about a friendly <br/> real estate agent</h4>
          </div>
          <div className="col-md-7 text-left">
            <p>Todays home buying is an art of technology and engineering adaptation. There are so many parameters which govern the home quality and its price.</p>
            <p className="font-italic font-weight-light">Ruby, a civil engineering degree holder, has been servicing the North Atlanta area for buying and selling the houses since 2004. A friendly real-estate expert in deed.</p>
            <p>Your concerns and needs are addressed with utmost sincerity and knowledge.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default Home