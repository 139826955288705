import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Footer() {
  return (
    <div id="footer" className="container mt-5">
      <div className="row text-left justify-content-start">
        <div className="col-md-4 my-2">
          <h4 className="text-uppercase font-weight-bold">Find us</h4>
          <address>
              <strong>Address</strong><br/>
              10900 Crabapple Road<br/>
              Rosewell, GA 30075<br/>
          </address>
          <div>
            <strong>Hours</strong><br/>
            Monday—Friday: 9:00AM–5:00PM<br/>
            Saturday: 11:00AM–3:00PM<br/>
          </div>
        </div>
        <div id="contact-us" className="col-md-4 my-2">
          <h4 className="text-uppercase font-weight-bold">Contact us</h4>
          <ul className="fa-ul">
            <li><span className="fa-li"><FontAwesomeIcon icon="envelope" /></span><a href="mailto:contact@rubyjacob.com">contact@rubyjacob.com</a></li>
            <li><span className="fa-li"><FontAwesomeIcon icon="phone" /></span>(678) 615 8757</li>
          </ul>
        </div>
        <div className="col-md-4 my-2">
          <h4 className="text-uppercase font-weight-bold">Tools</h4>
          <a href="https://www.workforce-resource.com/dpr/pmt/FMLS/RUBY_JACOB" target="_blank" rel="noopener noreferrer"><img src="money_available_@2x.png" className="img-fluid" alt="DownPayment Assistance" /></a>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="footer-copyright text-center py-3">© {(new Date().getFullYear())} Copyright:
            <a href="/"> RubyJacob.com</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer
