import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import logo from '../../logo.png';
import './menu.scss'

function Menu() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <div className="container">
        <a className="navbar-brand" rel="noopener noreferrer" href="/">
          <img src="/android-chrome-192x192.png" width="30" height="30" className="d-inline-block align-top" alt="" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" rel="noopener noreferrer" href="#home">Home</a>
            </li>
          </ul>
          <div className="mr-3">
            <a href="https://www.workforce-resource.com/dpr/pmt/FMLS/RUBY_JACOB" target="_blank" rel="noopener noreferrer"><img src="money_available_icon.png" className="img-fluid" alt="DownPayment Assistance" width="50"  /><small><span className="text-green font-weight-bolder">Find Down Payment Help Now</span></small></a>
          </div>
          <span className="navbar-text">
            <a href="#content" rel="noopener noreferrer">
              <FontAwesomeIcon icon="arrow-down" />
            </a>
          </span>
        </div>
      </div>
    </nav>
  );
}

export default Menu
